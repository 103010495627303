import {TitleHeader} from "@/components/TitleHeader";
import {LeftCircleIcon, SettingIcon} from "@/components/SvgIcons";
import {IndexCard} from "@/components/IndexCard";
import "./Dice.scss";
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";


function SingleCube() {
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({x: 0, y: 0});
    const [preRotation, setPreRotation] = useState({yaw: 30, pitch: 35.12, roll: 45});
    const [rotation, setRotation] = useState({yaw: 30, pitch: 35.12, roll: 45});

    const rotateVectorByQuaternion = useCallback((vector, quaternion) => {
        let vetcorQuat = {w: 0, x: vector.x, y: vector.y, z: vector.z};
        let t1 = quaternionMultiply(quaternion, vetcorQuat);
        let t2 = quaternionMultiply(t1, {
            w: quaternion.w,
            x: -quaternion.x,
            y: -quaternion.y,
            z: -quaternion.z,
        });
        return {x: t2.x, y: t2.y, z: t2.z};
    }, []);

    const handleRotate = useCallback((angleY, angleX) => {
        // 计算当前竖直
        let axisScreenY = rotateVectorByQuaternion(
            {x: 0, y: 1, z: 0},
            inverseQuaternion(eulerToQuaternion(preRotation)),
        );
        // 计算横向移动的旋转
        // 计算当前四元数
        const rotateQuaternionY = quaternionMultiply(eulerToQuaternion(preRotation), {
            x: axisScreenY.x * Math.sin(angleY / 2),
            y: axisScreenY.y * Math.sin(angleY / 2),
            z: axisScreenY.z * Math.sin(angleY / 2),
            w: Math.cos(angleY / 2),
        });

        // 计算当前水平轴
        let axisScreenX = rotateVectorByQuaternion(
            {x: 1, y: 0, z: 0},
            inverseQuaternion(eulerToQuaternion(preRotation)),
        );
        // 计算当前四元数
        const currentQuaternionX = quaternionMultiply(rotateQuaternionY, {
            x: axisScreenX.x * Math.sin(angleX / 2),
            y: axisScreenX.y * Math.sin(angleX / 2),
            z: axisScreenX.z * Math.sin(angleX / 2),
            w: Math.cos(angleX / 2),
        });

        // 将四元数转换为欧拉角，并将其应用于立方体的旋转样式中
        const euler = quaternionToEuler(currentQuaternionX);

        setRotation(euler);
    }, [preRotation, rotateVectorByQuaternion]);

    useEffect(() => {
        const intervalNum = setInterval(() => {
            if (!isDragging) {
                handleRotate(0.005, 0);
                setPreRotation(rotation);
            }
        }, 8);
        return () => clearInterval(intervalNum);
    }, [handleRotate, isDragging, rotation]);

    const handleMouseDown = (event) => {
        if (isDragging)
            return;
        setIsDragging(true);
        setDragStart({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            const dx = event.clientX - dragStart.x;
            const dy = event.clientY - dragStart.y;

            const angleY = dx * 0.01;
            const angleX = dy * 0.01;

            handleRotate(angleY, angleX);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setPreRotation(rotation);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
        setPreRotation(rotation);
    };

    const handleTouchStart = (event) => {
        if (isDragging)
            return;
        event.preventDefault();
        setIsDragging(true);
        setDragStart({
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
        });
    };

    const handleTouchMove = (event) => {
        if (isDragging) {
            event.preventDefault();
            const dx = event.touches[0].clientX - dragStart.x;
            const dy = event.touches[0].clientY - dragStart.y;

            const angleY = dx * 0.01;
            const angleX = dy * 0.01;

            handleRotate(angleY, angleX);
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        setPreRotation(rotation);
    };

    const handleTouchCancel = () => {
        setIsDragging(false);
        setPreRotation(rotation);
    };

    function eulerToQuaternion(euler) {
        let yaw = Math.PI * euler.yaw / 180;
        let pitch = Math.PI * euler.pitch / 180;
        let roll = Math.PI * euler.roll / 180;

        let cy = Math.cos(yaw * 0.5);
        let sy = Math.sin(yaw * 0.5);
        let cp = Math.cos(pitch * 0.5);
        let sp = Math.sin(pitch * 0.5);
        let cr = Math.cos(roll * 0.5);
        let sr = Math.sin(roll * 0.5);

        let qw = cy * cp * cr + sy * sp * sr;
        let qx = cy * cp * sr - sy * sp * cr;
        let qy = sy * cp * sr + cy * sp * cr;
        let qz = sy * cp * cr - cy * sp * sr;
        // console.log(qw * qw + qx * qx + qy * qy + qz * qz);
        return {x: qx, y: qy, z: qz, w: qw};
    }

    // 默认模长为 1
    function inverseQuaternion(q) {
        return {w: q.w, x: -q.x, y: -q.y, z: -q.z};
    }

    function quaternionToEuler(q) {
        let ysqr = q.y * q.y;

        // roll (x-axis rotation)
        let t0 = +2.0 * (q.w * q.x + q.y * q.z);
        let t1 = +1.0 - 2.0 * (q.x * q.x + ysqr);
        let roll = Math.atan2(t0, t1);

        // pitch (y-axis rotation)
        let t2 = +2.0 * (q.w * q.y - q.z * q.x);
        t2 = t2 > 1.0 ? 1.0 : t2;
        t2 = t2 < -1.0 ? -1.0 : t2;
        let pitch = Math.asin(t2);

        // yaw (z-axis rotation)
        let t3 = +2.0 * (q.w * q.z + q.x * q.y);
        let t4 = +1.0 - 2.0 * (ysqr + q.z * q.z);
        let yaw = Math.atan2(t3, t4);

        yaw = yaw * 180 / Math.PI;
        pitch = pitch * 180 / Math.PI;
        roll = roll * 180 / Math.PI;

        return {yaw: yaw, pitch: pitch, roll: roll};
    }

    const quaternionMultiply = (q1, q2) => {
        // 四元数乘法
        return {
            w: q1.w * q2.w - q1.x * q2.x - q1.y * q2.y - q1.z * q2.z,
            x: q1.w * q2.x + q1.x * q2.w + q1.y * q2.z - q1.z * q2.y,
            y: q1.w * q2.y - q1.x * q2.z + q1.y * q2.w + q1.z * q2.x,
            z: q1.w * q2.z + q1.x * q2.y - q1.y * q2.x + q1.z * q2.w,
        };
    };

    const rotateVectorByEulerAngles = (vector, euler) => {
        let yaw = Math.PI * euler.yaw / 180;
        let pitch = Math.PI * euler.pitch / 180;
        let roll = Math.PI * euler.roll / 180;

        const cosYaw = Math.cos(yaw);
        const sinYaw = Math.sin(yaw);
        const cosPitch = Math.cos(pitch);
        const sinPitch = Math.sin(pitch);
        const cosRoll = Math.cos(roll);
        const sinRoll = Math.sin(roll);

        // 计算旋转矩阵
        const R11 = cosYaw * cosPitch;
        const R12 = cosYaw * sinPitch * sinRoll - sinYaw * cosRoll;
        const R13 = cosYaw * sinPitch * cosRoll + sinYaw * sinRoll;
        const R21 = sinYaw * cosPitch;
        const R22 = sinYaw * sinPitch * sinRoll + cosYaw * cosRoll;
        const R23 = sinYaw * sinPitch * cosRoll - cosYaw * sinRoll;
        const R31 = -sinPitch;
        const R32 = cosPitch * sinRoll;
        const R33 = cosPitch * cosRoll;

        // 计算旋转后的向量
        const x = vector.x;
        const y = vector.y;
        const z = vector.z;
        const x1 = R11 * x + R12 * y + R13 * z;
        const y1 = R21 * x + R22 * y + R23 * z;
        const z1 = R31 * x + R32 * y + R33 * z;

        return {x: x1, y: y1, z: z1};
    };


    return (
        <div className={"dice-wrapper"}
             onMouseDown={handleMouseDown}
             onMouseMove={handleMouseMove}
             onMouseUp={handleMouseUp}
             onMouseLeave={handleMouseLeave}
             onTouchStart={handleTouchStart}
             onTouchEnd={handleTouchEnd}
             onTouchMove={handleTouchMove}
             onTouchCancel={handleTouchCancel}
        >
            <div className="cube"
                 style={{
                     "transform": `rotateZ(${-rotation.yaw}deg) rotateY(${rotation.pitch}deg) rotateX(${-rotation.roll}deg)`,
                     // "transform": `rotateX(${-rotation.x}deg) rotateY(${rotation.y}deg) rotateZ(${-rotation.z}deg)`,
                     // "--edge-width": "400px",
                 }}
            >
                <div className="front">
                    <img src={"/dice/1.png"} alt={"1"}/>
                </div>
                <div className="back">
                    <img src={"/dice/6.png"} alt={"6"}/>
                </div>
                <div className="left">
                    <img src={"/dice/2.png"} alt={"2"}/>
                </div>
                <div className="right">
                    <img src={"/dice/5_rotate.png"} alt={"5"}/>
                </div>
                <div className="top">
                    <img src={"/dice/4.png"} alt={"4"}/>
                </div>
                <div className="bottom">
                    <img src={"/dice/3_rotate.png"} alt={"3"}/>
                </div>
            </div>
        </div>
    );
}


export default function Dice() {
    let nav = useNavigate();

    return (
        <div>
            <TitleHeader title="骰子" onClick={() => nav("/home")} icon={<LeftCircleIcon/>}/>
            <SingleCube/>
        </div>
    );
}