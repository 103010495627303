import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import {HashRouter} from "react-router-dom";
import {Alert} from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <HashRouter>
        <Alert.ErrorBoundary>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </Alert.ErrorBoundary>
    </HashRouter>,
);

