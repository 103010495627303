import {Card, Typography} from "antd";
import {useNavigate} from "react-router-dom";

const {Title, Paragraph} = Typography;

export function IndexCard({url, title, paragraph, cardStyle}) {
    const nav = useNavigate();
    // const router = useRouter();
    return (
        <Card
            className="fancy-card"
            hoverable
            style={{width: "100%"}}
            size="small"
            onClick={() => !!url && nav(url)}
            bodyStyle={cardStyle}
        >
            <Title level={5}>{title}</Title>
            <Paragraph style={{marginBottom: 0}}>
                {paragraph}
            </Paragraph>
        </Card>
    );
}

export function ClickCard({onClick, title, paragraph, cardStyle}) {
    return (
        <Card
            className="fancy-card"
            hoverable
            style={{width: "100%"}}
            size="small"
            onClick={onClick}
            bodyStyle={cardStyle}
        >
            <Title level={5}>{title}</Title>
            <Paragraph style={{marginBottom: 0}}>
                {paragraph}
            </Paragraph>
        </Card>
    );
}