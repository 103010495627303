import {TitleHeader} from "@/components/TitleHeader";
import {IndexCard} from "@/components/IndexCard";
import {Empty} from "antd";
import "@/styles/Home.scss";
import {SettingIcon} from "@/components/SvgIcons";

export default function Home() {


    return (
        <div className="home-container">
            <TitleHeader title="妙妙屋" icon={<SettingIcon/>}/>

            {/*<IndexCard url={"/cube"} title={"魔方"} paragraph={"一个魔方的demo"}/>*/}

            <IndexCard url={"/dice"} title={"骰子"} paragraph={"一个只能看不能玩的迷宫骰子"}/>
        </div>
    );
}