import "./App.scss";
import {Footer} from "@/components/Footer";
import {Alert} from "antd";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "@/pages/Home";
import Cube from "@/pages/Cube";
import Dice from "@/pages/Dice";

function App() {

    return (
        <div id={"global-wrapper"}>
            <div id={"page-wrapper"}>
                <Alert.ErrorBoundary>
                    <Routes>
                        <Route exact path="/" element={<Navigate to="/home" replace/>}/>
                        <Route exact path="/home" element={<Home/>}/>
                        {/*<Route exact path="/cube" element={<Cube/>}/>*/}
                        <Route exact path="/dice" element={<Dice/>}/>

                        <Route path="*" element={<Navigate to="/home" replace/>}/>
                    </Routes>
                </Alert.ErrorBoundary>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
