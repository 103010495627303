import styles from "@/styles/Footer.module.scss";


export function Footer() {
    return (
        <div className={styles.footer}>
            <p>
                @P&KU
            </p>
        </div>
    );
}